import React, { useEffect, useState } from 'react';

type userInfo = {
    id: string;
    first_name: string;
    last_name: string;
}

const UserList = () => {

    const [users, setUsers] = useState<userInfo[]>([]);

    useEffect(() => {
        const url = '/api/users';
        fetch(url)
            .then((res) => res.json())
            .then(data => setUsers(data))
            .catch((error) => {
                console.log(error)
            });
    }, [])

    return (
        <div>
            <h3>Users</h3>
            {users && users.length > 0 &&
                <div>
                    {
                        users.map((user, i) => {
                            return <div key={user.id}>{user.id}. {user.first_name}</div>
                        })
                    }
                </div>
            }
        </div>
    )
}

export default UserList